body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans",
    "Droid Sans", "Helvetica Neue", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace;
}

/* Theming of Amazon-Cognito elements */
:root {
  --amplify-primary-color: rgb(204, 0, 7);
  --amplify-primary-tint: rgb(229, 0, 8);
  --amplify-primary-shade: #e0573e;
  --amplify-loading-spinner: {
    --spinner-circle-fill: white;
    --spinner-bar-fill: white;
  }
}

amplify-authenticator {
  --container-height: auto !important;
  --border-radius: 0px;
  --box-shadow: none;
}

/* this should work in a nice world */
amplify-sign-in .button {
  border-radius: 4px;
}

#tsparticles {
  width: 100%;
  height: 100%;
  position: fixed;
  margin: 0;
  padding: 0;
  top: 0;
  left: 0;
  z-index: -1;
}

.tsparticles-canvas-el {
  width: 100%;
  height: 100%;
}

.grecaptcha-badge {
  visibility: hidden;
}
